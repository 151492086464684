<template>
    <div class="single-card text-center mb-30" data-aos="fade-left">
        <div class="card-top" style="border:0">
            <h3 class="text-blue-light text-uppercase">{{ title }}</h3>
        </div>
        <div class="card-bottom">
            <ul>
                <li class="font-weight-bold">
                    {{text}}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title', 'text'],
    name: 'TextCard'
}
</script>