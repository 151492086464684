<template>
    <form class="forms" @submit.prevent="submit" method="post" id="frm-login" novalidate="novalidate">

        <div class="form-group ">
            <label for="email"><span class="required-label">*</span>E-mail:</label>
            <input name="email" type="text" class="form-control" required v-model="email">
            <div class="msg-validate">{{errorMail}}</div>
        </div>

        <div class="form-group ">
            <label for="password"><span class="required-label">*</span>Senha: <i :class="passwordIcon" :title="passwordTooltip" style="cursor: pointer" @click="switchVisibility"></i></label>
            <input name="password" :type="passwordFieldType" class="form-control" required v-model="password">
            <div class="msg-validate">{{errorPassword}}</div>
        </div>

        <div class="form-group ">
            <label for="coupon">Cupom:</label>
            <input name="coupon" type="text" class="form-control coupon" v-model="coupon">
            <div class="msg-validate">{{errorCoupon}}</div>
        </div>

        <div id="captcha" class="d-flex f-start g-recaptcha" data-sitekey="6Ldb2Z4aAAAAAMK94rlGuxhDKhI200saGxRZyrzL"></div>
        <div class="msg-validate"></div>

        <button class="btn card-btn1 w-100 mt-2" type="button" id="btn-login" :style="formButtonStyle" :disabled="isSubButtonDisabled" @click="submit()">
            <ClipLoader :loading="!isSubButtonDisabled" :color="'#e8e221'" :size="'35px'"  id="clipspin1" v-show="true" v-if="isSubButtonDisabled" style="margin-bottom: 25px;"/>
            <span v-if="!isSubButtonDisabled">Assinar</span><span v-if="isSubButtonDisabled">Processando</span>
        </button>
    </form>
</template>

<script>

import { LoginApi } from '@/functions/LoginApi.js';
import { ClipLoader } from 'vue3-spinner';

export default{
    name: "SubLoginForm",
    created(){
        if(process.env.VUE_APP_DEFAULT_INPUTS_VALUES != "true"){
            this.email = '';
            this.password = '';
            this.coupon = '';
        }
    },

    components: {        
        ClipLoader
    },

    data: function(){
        return {
            email: 'convidado@sensorial.life',
            password: '0000',
            coupon: null,
            
            errorMail: '',
            errorPassword: '',
            errorCoupon: '',
            
            isSubButtonDisabled: false,   
            formButtonStyle: "",
            
            passwordTooltip: "Mostrar senha",
            passwordIcon: "fa fa-eye-slash",
            passwordFieldType: "password",
        }
    },

    methods: {

        async submit(){
            console.log("submit");
            this.$parent.clearGlobalAlert();
            this.toggleButtonBehavior();            
            var loginApi = new LoginApi();

            if(this.validation(loginApi.validateLogin(this.email, this.password))){
                this.login(await loginApi.doLogin(this.email, this.password));                
            }
            else{
                this.$parent.globalAlert = 'Corrija os campos abaixo';                
            }
            this.toggleButtonBehavior();            
            this.$parent.focusGlobalAlert();
        },
        
        validation: function(evaluation){
            console.log("validation");
            //console.log(evaluation);

            this.errorMail = "";
            this.errorPassword = "";            
            
            if(evaluation.isValid === false){
                this.errorMail = evaluation._errorEmail;
                this.errorPassword = evaluation._errorPassword;
            }

            return evaluation.isValid;            
        },

        login: function(loginResponse){
            console.log("login");
            //console.log(loginResponse);

            let success;

            if(loginResponse._success === false){                
                this.$parent.globalAlert = loginResponse._error;
            }
            else{
                this.$parent.userId = loginResponse._userId;
                this.$parent.userToken = loginResponse._token;
                
                this.$parent.showUserDataModal()
            }

            success = loginResponse._success;
            return success;
        },

        toggleButtonShowLoader: function () {
            this.isSubButtonDisabled = !this.isSubButtonDisabled;
        },

        toggleButtonStyle: function () {
            if(this.isSubButtonDisabled){
                this.formButtonStyle = {'pointer-events' : 'none'};                
            }
            else{
                this.formButtonStyle = '';                
            }
        },

        toggleButtonBehavior: function () {
            this.toggleButtonShowLoader();
            this.toggleButtonStyle();
        },

        switchVisibility: function () {            
            if (this.passwordFieldType === "password") {
                this.passwordFieldType = "text";
                this.passwordTooltip = "Esconder senha";
                this.passwordIcon = "fa fa-eye";
            }
            else {
                this.passwordFieldType = "password";
                this.passwordTooltip = "Mostrar senha";
                this.passwordIcon = "fa fa-eye-slash";
            }
        },
    }
}
</script>

