<template>
    

    <div class="available-app-area">
        <div class="container">
            <div class="row d-flex justify-content-between">
                <div class="col-xl-5 col-lg-6">
                    <div class="app-caption">
                        <div class="section-tittle section-tittle3">
                            <h2 class="text-yellow">Faça o download do app já!</h2>
                            <p>Desenvolvido para Tablets!!!</p>
                            <div class="app-btn">
                                <a href="https://play.google.com/store/apps/details?id=com.sensorial.moove.trainer" target="_blank" class="app-btn1">
                                    <img src="@/assets/img/btn-google.png" alt="">
                                </a>
                                <a href="https://apps.apple.com/br/app/sensorial-moove-trainer/id6444312428" target="_blank" class="app-btn2">
                                    <img src="@/assets/img/btn-apple.png" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
  name: 'DownloadMoovePage',

  methods: {
        handleScroll(){            
            document.title='Sensorial Moove Trainer | Download';
        }
    },
   
    mounted: function () {
        let pageElement  = document.querySelector('.available-app-area');
        pageElement.addEventListener('mouseenter', this.handleScroll, {once : false});
    },
    unmounted: function () {
        let pageElement  = document.querySelector('.available-app-area');
        pageElement.removeEventListener('mouseenter', this.handleScroll, {once : false});
    }
}
</script>

<style scoped>
.h1{
    color: #5e0404;
}
</style>