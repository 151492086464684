<template>
    <div v-for="AboutCardJson in AboutCardsJson" :key="AboutCardJson.title" class="col-xl-4 col-lg-4 col-md-6">
        <TextCard :title="AboutCardJson.title" :text="AboutCardJson.text"/>
    </div>
</template>

<script>
import AboutCardsJson from '../../../json/about-cards.json'

import TextCard from '../general/TextCard.vue';

export default {
    props: ['img', 'title'],
    name: 'HomePage',
    components:{
        TextCard
    },
    data(){
        return {
            AboutCardsJson: AboutCardsJson
        }
    }
}
</script>