<template>
    <div class="col-xl-6 col-lg-6 col-md-6">
        <div class="single-features mb-70">
            <div class="features-icon">
                <img class="img-invert" :src="img">

            </div>
            <div class="features-caption">
                <h3 style="color: #fff;">{{ title }}</h3>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['img', 'title'],
    name: 'AboutCapacity',
}
</script>

<style scoped>
</style>