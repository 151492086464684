<template>
    <header>
        <div class="mt-40 header-transparrent ">
            <div class="main-header">
                <div class="container">
                    <div class="row align-items-center">
                        <!-- Logo -->
                        <div class="col-xl-4 col-lg-2 col-md-2 col-7 ">
                            <div class="logo">
                                <a href="/"><img src="@/assets/img/logo-w.png" alt="" class="img-fluid"></a>
                            </div>
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-10">
                            
                        </div>
                        <!-- Mobile Menu -->
                        <div class="col-12">
                            <div class="mobile_menu d-block d-lg-none"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
  name: 'HeaderPage',
}
</script>

<style scoped>
</style>