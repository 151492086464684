<template>
    <section class="best-features-area pt-50 pb-50">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="section-tittle">
                        <h1>
                            Desenvolvido por Doutores em Neurociências, 
                            Psicologia, Fisioterapia e profissionais da 
                            Educação Física
                        </h1>
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-xl-4 col-lg-4  align-self-center d-xs-none">
                    <div class="row">
                        <img src="@/assets/img/brain.png" class="img-fluid " alt="">
                    </div>
                </div>
                <div class="col-xl-8 col-lg-8">
                    <!-- Section Tittle -->

                    <!-- Section caption -->
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-5">
                            <div class="section-tittle">
                                <h3>
                                    Os cientistas da Sensorial desenvolveram 
                                    atividades de estimulação cognitiva que 
                                    podem ser aplicadas com e sem movimento 
                                    para desenvolver capacidades do cérebro como:
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <!-- <AboutCapacities/> -->
                        <AboutCapacitiy :img="require('@/assets/img/005-goal-64.png')" title="ATENÇÃO"/>
                        <AboutCapacitiy :img="require('@/assets/img/003-brain-64.png')" title="TOMADA DE DECISÃO"/>
                        <AboutCapacitiy :img="require('@/assets/img/ideia-icon-64.png')" title="RACIOCÍNIO"/>
                        <AboutCapacitiy :img="require('@/assets/img/memory-icon-64.png')" title="MEMÓRIA"/>
                        <AboutCapacitiy :img="require('@/assets/img/001-warning-64.png')" title="CONTROLE DA IMPULSIVIDADE"/>
                        <AboutCapacitiy :img="require('@/assets/img/002-checked-64.png')" title="REAÇÃO"/>
                        
                        
                    </div>
                </div>
            </div>
        </div>

        <div class="pricing-card-area">
            <div class="container">
                <div class="row">
                    <AboutCards/>                    
                </div>
            </div>
        </div>

    </section>
</template>

<script>

import AboutCapacitiy from '../elements/about/AboutCapacity.vue';
import AboutCapacitiesJson from '../../json/capacities.json'
import AboutCards from '../elements/about/AboutCards.vue';

export default {
  name: 'AboutMoovePage',
  components: {
    AboutCapacitiy,    
    AboutCards   
  },

  data(){
        return {
            AboutCapacitiesJson: AboutCapacitiesJson
        }
    },

    methods: {
        handleScroll(){            
            document.title='Sensorial Moove Trainer | Sobre';
        }
    },
   
    mounted: function () {
        let pageElement  = document.querySelector('.best-features-area');
        pageElement.addEventListener('mouseenter', this.handleScroll, {once : false});
    },
    unmounted: function () {
        let pageElement  = document.querySelector('.best-features-area');
        pageElement.removeEventListener('mouseenter', this.handleScroll, {once : false});
    }
}
</script>

<style scoped>
.h1{
    color: #5e0404;
}
</style>