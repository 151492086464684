<template>
    <section class="section-highlight">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-8 col-xs-10 mt-4 mb-5">
                    <div class="text-left ">
                        <h2 class="text-white font-italic">
                            Adicione atividades de treinamento cognitivo 
                            aos seus protocolos de atendimento e desenvolva 
                            o cérebro dos seus clientes de maneira gamificada.

                        </h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'MessagePage',

  methods: {
        handleScroll(){            
            document.title='Sensorial Moove Trainer | Sobre';
        }
    },
   
    mounted: function () {
        let pageElement  = document.querySelector('.section-highlight');
        pageElement.addEventListener('mouseenter', this.handleScroll, {once : false});
    },
    unmounted: function () {
        let pageElement  = document.querySelector('.best-features-area');
        pageElement.removeEventListener('mouseenter', this.handleScroll, {once : false});
    }
}
</script>

<style scoped>
.h1{
    color: #5e0404;
}
</style>