<template>
    <div class="slider-area ">
        <div class="slider-active">
            <div class="single-slider slider-height bg-hero d-flex align-items-center">
                <div class="container">
                    <div class="row d-flex align-items-center">
                        <div class="col-lg-7 col-md-9 ">
                            <div class="hero__caption">
                                <h1 data-animation="fadeInUp" data-delay="1.5s">
                                    Desenvolva o cérebro dos seus clientes de maneira 
                                    divertida e gamificada através de atividades 
                                    desenvolvidas por Doutores em Neurociências
                                </h1>
                                <p data-animation="fadeInUp" data-delay="1.6s">
                                    Inclua o cuidado ao cérebro de maneira descomplicada 
                                    ao seu dia-a-dia de trabalho.
                                </p>

                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="hero__img  f-right" data-animation="fadeInRight"
                                    data-delay="1.7s">
                                <img src="@/assets/img/mosaico-banner.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
  name: 'HomePage',

  methods: {
        handleScroll(){            
            document.title='Sensorial Moove Trainer';
        }
    },

    mounted: function () {
        let pageElement  = document.querySelector('.slider-area');        
        pageElement.addEventListener('mouseenter', this.handleScroll, {once : false});
    },
    unmounted: function () {
        let pageElement  = document.querySelector('.slider-area');        
        pageElement.removeEventListener('mouseenter', this.handleScroll, {once : false});
    }
}
</script>

<style scoped>
</style>