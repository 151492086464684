<template>
    <div class="say-something-aera pt-10 pb-10 fix">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-xl-12 col-12 text-center">
                        <h1>Cérebro e Movimento - O verdadeiro trabalho da pessoa como um todo</h1>
                    </div>
                </div>
                <div class="row mt-40">
                    <div class="col-xl-12 col-lg-12 text-center">
                        <div style="width:100%;max-width:550px;overflow:hidden;padding: 0;margin: 0 auto;">
                            <video style="width: 100%;" src="@/assets/video/Mooveapp.webm" controls autoplay muted></video>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-between align-items-center mt-30">

                    <div class="col-xl-6 col-lg-6">

                        <div class="say-something-cap">
                            <img class="img-fluid" src="@/assets/img/img-relatorio.png">
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6">

                        <div class="say-something-cap text-center mt-40">
                            <h3 class="font-italic" style="font-size: 1.5em;">Obtenha a licença anual por:</h3>
                            <h1 class="text-yellow" style="font-size: 4.5em;">R$ 599,90</h1>
                        </div>
                        <div class="say-btn text-center">
                            <a href="#checkout" class="btn radius-btn">Assine já</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="app-shape">
                <img src="@/assets/img/shape/app-shape-top.png" alt=""
                     class="app-shape-top heartbeat d-none d-lg-block img-fluid float-right mr-200 mb-40">
            </div> -->
        </div>
</template>

<script>
export default {
  name: 'PricePage',

  methods: {
        handleScroll(){            
            document.title='Sensorial Moove Trainer | Preço';
        }
    },
   
    mounted: function () {
        let pageElement  = document.querySelector('.say-something-aera');
        pageElement.addEventListener('mouseenter', this.handleScroll, {once : false});
    },
    unmounted: function () {
        let pageElement  = document.querySelector('.say-something-aera');
        pageElement.removeEventListener('mouseenter', this.handleScroll, {once : false});
    }
}
</script>

<style scoped>
.h1{
    color: #5e0404;
}
</style>