<template>
    <a name="checkout"></a>
    <div class="section-ckeckout pt-90 pb-90 fix">
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div class=" col-xl-12 col-lg-12">
                    <div class="say-something-cap">
                        <h2 class="text-white text-center">Assine Agora a licença anual por <span class="text-yellow" >R$ 599,90</span>!</h2>
                    </div>
                </div>

            </div>

            <!--- -->
            <div class="row ">
                <div class="col-xs-12 col-12">
                    <nav>
                        <div class="nav nav-tabs nav-fill" id="nav-tab">
                            <a class="nav-item nav-link" :class="loginVisible ? 'active' : ''" @click="changeForm('login')" >Já tenho usuário moove trainer</a>
                            <a class="nav-item nav-link" :class="registerVisible ? 'active' : ''" @click="changeForm('register')" >Cadastrar usuário moove trainer</a>
                        </div>
                    </nav>

                    <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">

                        <div class="alert alert-warning" v-if="globalAlert" role="alert"  ref="globalAlert" style="margin-top: 15px; margin-bottom: 15px;">
                            {{ globalAlert }}
                        </div>

                        <div class="tab-pane fade show active" v-if="loginVisible">
                            <SubLoginForm ref="LoginFormComponent"/>
                        </div>

                        <div class="tab-pane fade show active" id="nav-signup" v-if="registerVisible">
                            <SubRegisterForm/>
                        </div>
                    </div>

                </div>
            </div>


        </div>
        <!-- shape -->

    </div>
    <!-- Say Something End -->

    <UserData v-if="showModal" />
</template>

<script>

import SubLoginForm from '../elements/forms/SubLoginForm.vue';
import SubRegisterForm from '../elements/forms/SubRegisterForm.vue'

import UserData from '../elements/modals/UserData.vue';
import { SubscriptionApi } from '@/functions/SubscriptionApi.js';

export default {
    name: 'BuyForm',
    components: {
        SubLoginForm,
        SubRegisterForm,
        UserData
    },

    data: function(){
        let hash = window.location.hash;

        return {
            loginVisible: hash == '#login' || hash == '' ? true : false,
            registerVisible: hash == '#register' ? true : false,

            userId: '',
            userToken: '',
            
            paymentLink: '',
            
            globalAlert: '',
            
            showModal: false,

            subscriptionApi : new SubscriptionApi()
        }
    },
    
    methods: {
        changeForm: function(form){
            if(form == 'login'){
                this.loginVisible = true;
                this.registerVisible = false;
            }
            else if(form == 'register'){
                this.loginVisible = false;
                this.registerVisible = true;
            }

            this.globalAlert='';

            window.history.pushState({}, '', '/#'+form);
        },

        subscription(coupon){
            console.log("subscription");
            if(this.loginVisible) this.$refs.LoginFormComponent.toggleButtonBehavior();

            let _userId = this.userId;
            let _userToken = this.userToken;
            let _coupon = coupon;
            let loginData = {_userId, _userToken, _coupon};
            //console.log(loginData);
            /*loginData._userId = this.userId;
            loginData._userToken = this.userToken;
            loginData._coupon = coupon;*/

            this.doSubscription(loginData)
        },
        
        async doSubscription(loginData){
            /*
            this.paymentLink = 'https://cielolink.com.br/3Vm33Dk'

            this.doAfterLinkGenerated()*/
            console.log("doSubscription");
            let subscriptionResponse = await this.subscriptionApi.generatePaymentLink(loginData);            

            if (subscriptionResponse._success === false) {
                this.globalAlert = subscriptionResponse._error;
            }
            else {
                this.subscriptionApi.doAfterLinkGenerated(subscriptionResponse._paymentLink);
            }            
            if(this.loginVisible) this.$refs.LoginFormComponent.toggleButtonBehavior();
            /*
            fetch('https://pay-dev.sensorialsports.com/api/v1/subscription/trainer/web/subscribe', {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.userToken}`
                },
                body: JSON.stringify({ 
                    user_id: this.userId,
                    installments: 1,
                    coupon: 'asdasd',
                })
            })
            .then(async (response) => {

                var data = await response.json();

                if(response.status == 200){
                    this.paymentLink = data.payment_link
            
                    if(this.paymentLink){
                        this.doAfterLinkGenerated()
                    }
                    else{
                        this.isSubButtonDisabled = false;
                        this.globalAlert = 'Erro ao gerar link de pagamento, tente novamente mais tarde'
                    }
                }
                else{
                    if(response.coupon){
                        this.globalAlert = 'Cupom inválido'
                    }
                    else{
                        this.globalAlert = 'Erro ao fazer assinatura, tente novamente mais tarde'
                    }

                    this.isSubButtonDisabled = false;
                }
            })
            .catch(error => {
                this.isSubButtonDisabled = false;
                this.globalAlert = 'Erro ao fazer assinatura, tente novamente mais tarde'
                console.log(error)
            })*/
        },
        
        doAfterLinkGenerated(){
            window.open(this.paymentLink, '_blank')
        },

        showUserDataModal(){
            this.showModal = true;
            this.$parent.hideDocumentScroll();
        },

        closeUserDataModal(){
            this.showModal = false;
            this.$parent.showDocumentScroll();
        },
        
        focusGlobalAlert: function(){
            if(this.globalAlert != ''){
                this.$nextTick(() => {                    
                    this.$refs.globalAlert.scrollIntoView();
                });
            }
            console.log("focus");
        },
        
        clearGlobalAlert: function(){
            this.globalAlert='';
        },

        handleScroll(){            
            document.title='Sensorial Moove Trainer | Assinar';
        }
    },

    mounted: function () {
        let pageElement  = document.querySelector('.section-ckeckout');
        pageElement.addEventListener('mouseenter', this.handleScroll, {once : false});
    },
    unmounted: function () {
        let pageElement  = document.querySelector('.section-ckeckout');
        pageElement.removeEventListener('mouseenter', this.handleScroll, {once : false});
    }
}
</script>

